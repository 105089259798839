import { h, Component } from "preact";
import { StoreContextWrapper, Actions } from "../StoreContextWrapper";
import { connect } from "unistore/preact";
import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import { EventEmitter2 } from "eventemitter2";
import SpacesOverview from "../SpacesOverview";
import SpaceDetails from "../SpaceDetails";
import OpenChallenge from "../OpenChallenge";
import LecturerSignupForm from "../LecturerSignupForm";
import TeamFormationSignupForm from "../TeamFormationSignupForm";
import StakeholderSignupForm from "../StakeholderSignupForm";
import SpaceChallenge from "../SpaceChallenge";
import { getSdkStyles } from "../../services/helpers";

// import "./style.scss";

export interface McWidgetAppProps {
    options: McEmbedWidgetsSDKOptions;
    error?: string;
    stopLoading(): void;
    setError(error: any): void;
    startLoading(): void;
    setApiBasePath(basePath: string): void;
}

export interface McWidgetAppState {}

class McWidgetApp extends Component<McWidgetAppProps, McWidgetAppState> {
    constructor(props: McWidgetAppProps) {
        super(props);

        const {} = props.options;

        // If translation messages exists, overwrite base object
        this.state = {};
    }

    /**
     * Create global event emitter
     * @type {EventEmitter2}
     */
    events = new EventEmitter2();

    componentDidMount() {
        this.prepareInitialStore(this.props.options);
    }

    componentDidUpdate(prevProps: McWidgetAppProps) {
        // this.tokenValidation(prevProps.options, this.props.options);
        this.prepareInitialStore(prevProps.options);
    }

    /**
     * Prepare initial store
     * See https://github.com/developit/unistore
     * @param  {Object} options:      McEmbedWidgetsSDKOptions new config
     * @return {Function}
     */
    prepareInitialStore = (options: McEmbedWidgetsSDKOptions) => {
        const { apiBasePath } = options;

        if (apiBasePath) {
            this.props.setApiBasePath(apiBasePath);
        }
    };

    getWidget = (options: McWidgetAppProps["options"]) => {
        switch (options.widgetOptions.type) {
            case "space_details":
                return <SpaceDetails {...options} />;
            case "open_challenge":
                return <OpenChallenge {...options} />;
            case "space_challenge":
                return <SpaceChallenge {...options} />;
            case "spaces_overview":
                return <SpacesOverview {...options} />;
            case "lecturer_signup":
                return <LecturerSignupForm {...options} />;
            case "stakeholder_signup":
                return <StakeholderSignupForm {...options} />;
            case "team_formation_signup":
                return <TeamFormationSignupForm {...options} />;
            default:
                return null;
        }
    };

    render = ({ options }: McWidgetAppProps) => {
        const styles = options.widgetOptions.styles
            ? getSdkStyles(options.widgetOptions.styles)
            : null;

        return (
            <div {...(styles ? { style: styles } : {})}>
                {this.getWidget(options)}
            </div>
        );
    };
}

/**
 * Connect state to props
 * @param  {Object} state: any
 * @return {State}]
 */
const mapStateToProps = (state: any) => ({
    ...state,
});

const ConnectedMcWidgetApp = connect(mapStateToProps, Actions)(McWidgetApp);

const App = ({ options }: { options: McEmbedWidgetsSDKOptions }) => {
    return (
        <StoreContextWrapper>
            <ConnectedMcWidgetApp options={options} />
        </StoreContextWrapper>
    );
};

export default App;
