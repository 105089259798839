import { format } from "date-fns";
import McWidgetOptionStyles from "../models/McWidgetOptionStyles";

export const sortArray = (prop: string, arr: any[]) => {
    const propConst = prop.split(".");
    const len = propConst.length;

    arr.sort(function (a, b) {
        let i = 0;
        while (i < len) {
            a = a[propConst[i]];
            b = b[propConst[i]];
            i++;
        }
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    });
    return arr;
};

export const isEmpty = (obj) =>
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length;

/**
 * Word counter (custom validator for 'simple-react-validator')
 * @type {[type]}
 */
export const countWords = (s) => {
    s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, " "); //2 or more space to 1
    s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
    return s.split(" ").filter(function (str) {
        return str != "";
    }).length;
    //return s.split(' ').filter(String).length; - this can also be used
};

export const serialize: any = (obj: any, prefix: any) => {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push(
                v !== null && typeof v === "object"
                    ? serialize(v, k)
                    : encodeURIComponent(k) + "=" + encodeURIComponent(v)
            );
        }
    }
    return str.join("&");
};
export const prependObjectKeys = (pre: string, obj: any) => {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [pre + key, value])
    );
};

export const mysqlDateOrEmpty = (str) => {
    return str == "" ? "" : format(new Date(str), "yyyy-MM-dd");
};

export const pickTextColorBasedOnBgColorAdvanced = (
    bgColor: string,
    lightColor: string,
    darkColor: string
) => {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
};

export const getSdkStyles = (styles: McWidgetOptionStyles) => {
    // Calculate text color (black or white) based on contrast ratio with background.
    const btnTextColor = styles.primaryColor
        ? pickTextColorBasedOnBgColorAdvanced(
              styles.primaryColor,
              "#FFFFFF",
              "#000000"
          )
        : "#000000";
    const secondaryTextColor = styles.secondaryColor
        ? pickTextColorBasedOnBgColorAdvanced(
              styles.secondaryColor,
              "#FFFFFF",
              "#000000"
          )
        : "#FFFFFF";

    return {
        "--mc-sdk-ui-primary-color": styles?.primaryColor,
        "--mc-sdk-ui-secondary-color": styles?.secondaryColor,
        "--mc-sdk-ui-secondary-text-color": secondaryTextColor,
        "--mc-sdk-ui-btn-border-radius": styles?.buttonBorderRadius,
        "--mc-sdk-ui-btn-text-color": btnTextColor,
    };
};

export const scrollToId = (
    elementId: string,
    shadowRootContainerId: string
) => {
    const containerElOrg = document.querySelector(`#${shadowRootContainerId}`);
    const yOffset = -90;
    const element = containerElOrg?.shadowRoot?.querySelector(`#${elementId}`);
    const y =
        element &&
        element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
};
